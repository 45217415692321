.with {
  min-width: 99% !important;
  padding: 5px;
  text-align: center;
  margin: 0px !important;
}

img.infopics {
  display: block;
  max-width: min-content;
  max-height: min-content;
  width: auto;
  height: auto;
  text-align: end;
}

.object_fit_cover{
  object-fit: cover !important;
}

.bg_color_bule {
  background-color: #323172;
}

.bg_color_white {
  background-color: white;
}

.scrollbar_hidden {
  overflow-x: scroll;
  overflow-y: hidden;
  flex-wrap: nowrap !important;
}

.scrollbar_hidden::-webkit-scrollbar {
  display: none;
}
.button-contianer-left{
  position: absolute;
  top: 42%;
  left: 2%;
}
.button-contianer-right{
  position: absolute;
  top: 42%;
  right: 2%;
}
.img_carousel{
  position: relative;
}
.paper_img{
  /* height: 300vh; */
  background-repeat: round;
  background-size: cover;
}
.paper_data_viewer{
  overflow-x: auto;
}
.cursor_pointer{
  cursor: pointer;
}
.selected_border{
  border: 1px solid #323172;
  /* background-color: #323172; */
  /* opacity: 50; */
  position: relative;
}
.selected_border::before{
  content: "";
  position: absolute;
  left: 0;
  right:0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background:rgb(0 0 0 / 27%);
}

.pageNum {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  background: rgb(254 230 51 / 72%);
  width: 45px;
  height: 45px;
  border-radius: 50%;
  text-align: center;
  display: block;
  line-height: 45px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
.mt-80{
  margin-top: 80px;
}
.pt-120{
  padding-top: 135px;
}
.z-index-999{
  z-index: 999;
}


/* 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
